import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";

export const urlAPI =
  window.location.origin === "http://livesirs.petrograhamedika.com"
    ? "http://139.255.11.202:8080/pgm_backend"
    : window.location.origin === "http://www.livesirs.petrograhamedika.com"
    ? "http://139.255.11.202:8080/pgm_backend"
    : window.location.origin === "http://localhost:3001"
    ? process.env.REACT_APP_CONFIG_URL_API_LOCALDEV
    : window.location.origin === "http://localhost:3000"
    ? process.env.REACT_APP_CONFIG_URL_API_LOCALDEV
    : window.location.origin === "http://www.livesirs.rspg-group.com"
    ? "http://139.255.11.202:8080/pgm_backend"
    : window.location.origin === "http://livesirs.rspg-group.com"
    ? "http://139.255.11.202:8080/pgm_backend"
    : window.location.origin === "http://139.255.11.202:1234"
    ? "http://139.255.11.202:8080/pgm_backend"
    : window.location.origin === "http://192.168.10.4:1234"
    ? "http://192.168.10.4:80/pgm_backend"
    : window.location.origin === "http://localhost:3008"
    ? "http://localhost:8080/pgm_backend"
    : window.location.origin === "http://localhost:3007"
    ? "http://localhost:80/pgm_backend"
    : window.location.origin === "http://localhost:3004"
    ? "http://localhost:80/pgm_backend"
    : window.location.origin === "http://192.168.10.226:3004"
    ? "http://192.168.10.226:8080/pgm_backend"
    : window.location.origin === "http://192.168.10.226:3008"
    ? "http://192.168.10.226:8080/pgm_backend"
    : window.location.origin === "http://192.168.10.226:3007"
    ? "http://192.168.10.226:8080/pgm_backend"
    : "http://" + window.location.hostname + ":80/pgm_backend";

  // console.log ('window.location',window.location.origin);
  // console.log ('urlAPI',urlAPI);    
  export const urlSSAPI =
  window.location.origin === "http://livesirs.petrograhamedika.com"
    ? "http://139.255.11.202:8080/ss_backend"
    : window.location.origin === "http://www.livesirs.petrograhamedika.com"
    ? "http://139.255.11.202:8080/ss_backend"
    : window.location.origin === "http://localhost:3001"
    ? process.env.REACT_APP_CONFIG_URL_API_LOCALDEV
    : window.location.origin === "http://localhost:3000"
    ? "http://localhost:8080/ss_backend"
    : window.location.origin === "http://www.livesirs.rspg-group.com"
    ? "http://139.255.11.202:8080/ss_backend"
    : window.location.origin === "http://livesirs.rspg-group.com"
    ? "http://139.255.11.202:8080/ss_backend"
    : window.location.origin === "http://139.255.11.202:1234"
    ? "http://139.255.11.202:8080/ss_backend"
    : window.location.origin === "http://192.168.10.4:1234"
    ? "http://192.168.10.4:80/ss_backend"
    : window.location.origin === "http://localhost:3008"
    ? "http://localhost:8080/ss_backend"
    : window.location.origin === "http://localhost:3007"
    ? "http://localhost:8080/ss_backend"
    : window.location.origin === "http://localhost:3004"
    ? "http://localhost:80/ss_backend"
    : window.location.origin === "http://192.168.10.226:3004"
    ? "http://192.168.10.226:8080/ss_backend"
    : window.location.origin === "http://192.168.10.226:3008"
    ? "http://192.168.10.226:8080/pgm_backend"
    : window.location.origin === "http://192.168.10.226:3007"
    ? "http://192.168.10.226:8080/ss_backend"
    : "http://" + window.location.hostname + ":80/ss_backend";


export const multiPartSetting = {
  "Content-Type": `multipart/form-data;`,
};
export function fillObj(parent, obj) {
  for (let key of Object.keys(parent)) {
    if (obj[key]) {
      parent[key] = obj[key];
    }
  }
  return parent;
}

export function sumAllData(arrayOfObject, key = "") {
  return arrayOfObject.reduce((a, b) => a + (b[key] || 0), 0);
}

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};


// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.clear();
};

// set the token and user from the session storage
export const setUserSession = (token, user, expiredAt, ipKomp) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("expired", expiredAt);
  localStorage.setItem("ipKomp", ipKomp);
};

export function toFloatDecimal(number = "0") {
  let numberString = String(number);
  return parseFloat(numberString.replace(/\./g, "").replace(",", "."));
}

export function toNumber(number = "0") {
  return Number(number.toString().replace(/\./g, "").replace(",", "."));
}

export function BPJSAuth(sbu) {
  const user = "RS.PETROKIMIA GROUP";
  const timestamp = +new Date();
  const url = "https://apijkn-dev.bpjs-kesehatan.go.id/vclaim-rest-dev";

  let consID = "";
  let consSecret = "";
  let userKey = "";
  let ppkPelayanan = "";

  if (sbu === "RSPG") {
    consID = "1663";
    consSecret = "8pX414B244";
    userKey = "e80f1c5af04b02565040b04d129628f9";
    ppkPelayanan = "1302R002";
  } else if (sbu === "GRAHU") {
    consID = "3406";
    consSecret = "7kTEA16BE0";
    userKey = "6cc9cebba548b89735dd1dbe0e2f7cd6";
    ppkPelayanan = "0205R015";
  } else if (sbu === "DRIYO") {
    consID = "26772";
    consSecret = "0iC2C4F65D";
    userKey = "bbcd8e9214190be41fd85effba74e55e";
    ppkPelayanan = "0205R014";
  } else {
    consID = "";
    consSecret = "";
    userKey = "";
    ppkPelayanan = "";
  }

  const encodedSignature = Base64.stringify(
    hmacSHA256(consID + "&" + timestamp, consSecret)
  );

  const varReturn = {
    consID: consID,
    userKey: userKey,
    timestamp: timestamp,
    signature: encodedSignature,
    user: user,
    url: url,
  };

  return varReturn;
}
export const hashCode = (str) =>
  str.split("").reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

// export var ws = new WebSocket("ws://localhost:8282");
// export const wsProperty = {
//   user_id: JSON.parse(localStorage.getItem("user"))?.unit
//     ? hashCode(JSON.parse(localStorage.getItem("user"))?.unit)
//     : null,
//   recipient_id: null,
//   type: "socket",
//   token: null,
//   message: "",
//   title: "",
// };
